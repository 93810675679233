<template>
  <layout-modal-component
    :close-path="conditionalClosePath"
  >
    <template #header>
      <shared-page-header-component
        :data="data"
      />

    </template>

    <navigation-locale-menu-component
      :localizations="data?.localizations"
      :on-modal-page="true"
    />

    <shared-blocks-component
      :blocks="data?.blocks"
    />
  </layout-modal-component>
</template>

<script lang="ts" setup>
const props = defineProps({
  data: {
    type: Object
  },
  slugPages: {
    type: Array
  },
  prev: {
    type: Object,
    required: false
  },
  next: {
    type: Object,
    required: false
  },
  closePath: {
    type: String,
    required: false
  }
})

const conditionalClosePath = props?.closePath || getClosePath('index')
</script>
