<template>
  <div>
    <shared-blocks-page-entries-links-component
      :rich-text="richText"
      :links="entries"
    />

    <pages-index-slug-component
      v-if="pageData"
      :data="pageData"
      :close-path="closePath"
    />
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()

const props = defineProps({
  entries: Array,
  richText: {
    type: String,
    required: false
  },
  biennialEntryId: {
    type: Number,
    required: false
  }
})

const links = computed(() => {
  return props?.entries.map((entry: any) => {
    const entryObject = {
      ...entry,
      uid: 'pageEntry'
    }
    return getNavigationItem({
      entryObject,
      '__component': 'shared.link-collection-type',
    })
  })
})

const linksFiltered = (links: any) => {
  return links.filter((link: any) => {
    return getRoutePresence(getNavigationItem(link)?.link?.routeObject?.name)
  })
}

const closePath = computed(() => getClosePath('editions-slug-index-about', { slug: route.params.slug }))

const queryFromSlug = computed(() => route?.query?.page)

const pageData = ref(null)

const { data, error, execute } = await useAsyncData(() =>
  $fetch('/api/strapi-rest', { query: { path: queryPageEntryBySlugInterim(getPageQuery( {slug: queryFromSlug.value, filterValue: props?.biennialEntryId } ) ) } })
, {
    immediate: false,
  }
)

watch(queryFromSlug, (value) => {
  if (value) {
    execute()
  } else {
    pageData.value = null
  }
}, { immediate: true })

watch(data, (value) => {
  pageData.value = getFirstObject(data.value?.data)
}, { immediate: true })
</script>

<style module>
.root {

}
</style>
