import qs from 'qs'

const fields = [
  'title',
  'slug',
  'locale'
]

const populate = {
  ...queryFragmentBlocks,
  ...queryFragmentSeo,
}

export const queryPageEntryBySlugInterim = (options: queryOptions) => {
  const query = qs.stringify(
    {
      locale: options?.locale || 'en-US',
      status: options.status || 'published',
      filters: {
        $and: [
          {
            slug: {
              $eq: options.slug
            }
          },
          {
            biennialEntries: {
              id: options?.filterValue || undefined
            }
          }
        ]
      },
      fields,
      populate: {
        ...populate,
        localizations: {
          fields,
        },
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `page-entries?${query}`
}
