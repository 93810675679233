<template>
  <AccordionItem :class="$style.root">
    <div ref="rootTarget">
      <AccordionHeader :class="$style.header">
        <AccordionTrigger :class="$style.trigger">
          {{ item?.label?.trim() }}
          <div :class="$style.buttonContainer">
            <div :class="$style.button" data-outline>
              <SvgoPlus :class="$style.plus" />
              <SvgoMinus :class="$style.minus" />
            </div>
          </div>
        </AccordionTrigger>
      </AccordionHeader>
      <AccordionContent :class="$style.content">
        <core-text-component
          v-if="item?.value"
          :string="item?.value"
          :class="$style.text"
        />
      </AccordionContent>
    </div>
  </AccordionItem>
</template>

<script lang="ts" setup>
const rootTarget = ref(null)

const rootTargetVisible = useElementVisibility(rootTarget, {
  rootMargin: '0px 0px  0px',
})

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  active: {
    type: Boolean,
    required: false
  }
})

const isActive = computed(() => props?.active)

watchDebounced(isActive, (value) => {
  if (value && rootTarget.value instanceof HTMLElement) {
    rootTarget.value.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
}, { debounce: 500 },
)
</script>

<style module>
.root {
  border-bottom: var(--stroke--thin) solid var(--color--primary);
}

.root:first-of-type {
  border-top: var(--stroke--thin) solid var(--color--primary);
}

.header {
  composes: reset-heading from global;
}

.trigger {
  composes: reset-button from global;

  width: 100%;
  text-align: left;

  display: grid;
  grid-template-columns: 1fr 1.25em;
  gap: var(--unit--horizontal);
  align-items: center;

  padding-top: var(--unit--horizontal);
  padding-bottom: var(--unit--horizontal);
}

.content {
  margin-left: 0;
  overflow: hidden;
}

.content[data-state="open"] {
  animation: slideDown 300ms ease-out;
}
.content[data-state="closed"] {
  animation: slideUp 300ms ease-out;
}

.buttonContainer {
  position: relative;
}

.trigger {
  transition: color 50ms;
}

.trigger[data-state="open"] .button,
.trigger[data-state="closed"]:hover .button {
  color: var(--color--secondary);
}

.trigger[data-state="closed"] .minus {
  display: none;
}

.trigger[data-state="open"] .plus,
.trigger[data-state="closed"]:hover .plus {
  display: none;
}

.trigger[data-state="closed"]:hover .minus {
  display: block;
}

.button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 1ch;
}

.plus,
.minus {
  width: 100%;
  stroke: currentColor;
  stroke-width: var(--stroke--thin);
}

.text {
  composes: font-size-small from global;
  margin-top: calc(var(--unit--vertical) * 1);
  margin-bottom: var(--unit--vertical);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
</style>
