<template>
  <div
    v-if="downloads?.length"
    :class="$style.root"
  >
    <core-text-component
      v-if="richText"
      :class="$style.richText"
      :string="richText"
    />

    <ul :class="$style.list">
      <li
        v-for="(file, index) in files"
        :class="$style.item"
      >
        <a
          :href="file.url"
          :class="$style.link"
          download
          target="_blank"
          v-animated-underline-mouse-events
        >
          <span :class="$style.label" class="animated-underline">{{ file.label }}</span> <span :class="$style.details">{{ file?.mime?.split('/')?.pop()?.toUpperCase() }}, {{ file?.humanSize }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  downloads: {
    type: Array,
  },
  richText: {
    type: String,
    required: false,
  }
})

const files = props?.downloads?.map(download => {
  return {
    label: download?.label?.trim(),
    ...download?.file,
    humanSize: download?.file?.size ? `${(download?.file?.size / 1000).toFixed(2)}MB` : null,
    url: getAbsoluteFileUrl(download?.file?.url, download?.file?.provider)
  }
})?.filter(Boolean)
</script>

<style module>
.root {
  composes: font-size-small from global;
}

.richText {
  color: var(--color--secondary);
  margin-bottom: var(--font-small--line-height);
}

.list {
  composes: reset-list from global;
}

.item:not(:first-child) {
  border-top: var(--stroke--default) solid var(--color--secondary);
  margin-top: calc(var(--padding--element--top) - var(--stroke--default));
  padding-top: var(--padding--element--top);
}

.link {
  composes: reset-link from global;
}

.details {
  color: var(--color--secondary);
}
</style>
