<template>
  <div
    v-if="summaries"
    :class="$style.root"
  >
    <core-text-component
      v-if="richText"
      :class="$style.richText"
      :string="richText"
    />

    <AccordionRoot
      :class="$style.content"
      type="single"
      :collapsible="true"
      @update:modelValue="updateModelValue"
    >
      <shared-blocks-summary-list-details-component
        v-for="item in summaries"
        :key="item?.id"
        :item="item"
        :value="item?.id"
        :active="activeId === item?.id"
      />
    </AccordionRoot>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  richText: {
    type: String,
    required: false,
  },
  summaries: {
    type: Array,
    required: true
  }
})

const activeId = ref(null)

const updateModelValue = (value) => {
  activeId.value = value
}
</script>

<style module>
.root {
  --block--margin-bottom: calc(var(--unit--vertical) * 2);
}

[class*='components-core-blocks-rich-text'] + .root {
  --block--margin-top: calc(var(--unit--vertical) * 2);
}

.label {
  composes: font-size-small font-helvetica label from global;
}

.content {
  background-color: var(--core-summary-list--color--background, transparent);
  --color--primary: var(--core-summary-list--color--primary, currentColor);

  color: var(--core-summary-list--color--primary, currentColor);
}
</style>
