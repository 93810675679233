export const queryFragmentBlocks = {
  blocks: {
    on: {
      'blocks.downloads': {
        fields: ['richText'],
        populate: {
          downloads: {
            populate: '*'
          }
        }
      },
      'blocks.heading': {
        fields: ['text'],
      },
      'blocks.images': {
        fields: ['richText'],
        populate: {
          files: {
            populate: '*'
          }
        }
      },
      'blocks.note': {
        fields: ['richText'],
      },
      'blocks.rich-text': {
        fields: ['richText', 'richTextTruncated'],
      },
      'blocks.summary-list': {
        fields: ['richText'],
        populate: {
          summaries: {
            populate: '*'
          }
        }
      },
      'blocks.video-external': {
        fields: ['richText', 'videoExternal'],
      },
      'blocks.links': {
        populate: {
          links: {
            on: queryFragmentLinks
          }
        }
      },
      'blocks.video-mux': {
        fields: '*',
        populate: {
          muxAssets: true
        }
      },
      'blocks.sponsors': {
        fields: ['label'],
        populate: {
          items: {
            fields: ['name', 'url'],
            populate: {
              logo: {
                populate: '*'
              }
            }
          }
        }
      },
      'blocks.page-entries': {
        fields: ['richText'],
        populate: {
          pageEntries: {
            fields: ['title', 'slug', 'locale'],
            populate: {
              localizations: {
                fields: ['title', 'slug', 'locale'],
              }
            }
          }
        }
      }
    }
  }
}
