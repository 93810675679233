<template>
  <div :class="$style.root">
    <template
      v-for="(block, index) in blocks"
      v-if="blocks"
      :key="block?.id || index"
    >
      <shared-blocks-heading-component
        v-if="block?.__component === 'blocks.heading' && block?.text"
        :text="block?.text"
        :data--blockfirst="index === 0 ? '' : null"
        :data-last-block="index === (blocks?.length - 1) ? '' : null"
      />

      <shared-blocks-images-component
        v-else-if="block?.__component === 'blocks.images' && block?.files"
        :files="block?.files"
        :rich-text="block?.richText"
        :data-first-block="index === 0 ? '' : null"
        :data-last-block="index === (blocks?.length - 1) ? '' : null"
      />

      <shared-links-component
        v-else-if="block?.__component === 'blocks.links' && linksFiltered(block?.links)"
        :rich-text="block?.richText"
        :links="linksFiltered(block?.links)"
        :data-first-block="index === 0 ? '' : null"
        :data-last-block="index === (blocks?.length - 1) ? '' : null"
      />

      <shared-blocks-note-component
        v-else-if="block?.__component === 'blocks.note' && block?.richText"
        :rich-text="block?.richText"
        :data-first-block="index === 0 ? '' : null"
        :data-last-block="index === (blocks?.length - 1) ? '' : null"
      />

      <shared-blocks-rich-text-component
        v-else-if="block?.__component === 'blocks.rich-text' && block?.richText"
        :rich-text="block?.richText"
        :rich-text-truncated="block?.richTextTruncated"
        :default-font-size="defaultFontSize"
        :data-first-block="index === 0 ? '' : null"
        :data-last-block="index === (blocks?.length - 1) ? '' : null"
      />

      <shared-blocks-summary-list-component
        v-else-if="block?.__component === 'blocks.summary-list' && block?.summaries"
        :rich-text="block?.richText"
        :summaries="block?.summaries"
        :data-first-block="index === 0 ? '' : null"
        :data-last-block="index === (blocks?.length - 1) ? '' : null"
      />

      <shared-blocks-video-external-component
        v-else-if="block?.__component === 'blocks.video-external' && block?.videoExternal?.provider && block?.videoExternal?.providerUid"
        :provider-uid="block?.videoExternal?.providerUid"
        :provider="block?.videoExternal?.provider"
        :rich-text="block?.richText"
      />

      <shared-blocks-video-mux-component
        v-else-if="block?.__component === 'blocks.video-mux' && block?.muxAssets?.filter(i => i?.isReady === true)?.length > 0"
        :videos="block?.muxAssets?.filter(i => i?.isReady === true)"
        :rich-text="block?.richText"
        :include-controls="block?.includeControls === true ? true : false"
      />

      <shared-blocks-downloads-component
        v-else-if="block.__component === 'blocks.downloads' && block?.downloads"
        :rich-text="block?.richText"
        :downloads="block?.downloads"
      />

      <shared-blocks-sponsors-component
        v-if="block?.__component === 'blocks.sponsors' && block?.items?.length"
        :label="block?.label"
        :items="block?.items"
        :data--blockfirst="index === 0 ? '' : null"
        :data-last-block="index === (blocks?.length - 1) ? '' : null"
      />

      <shared-blocks-page-entries-component
        v-if="block?.__component === 'blocks.page-entries' && block?.pageEntries?.length"
        :entries="block?.pageEntries"
        :rich-text="block?.richText"
        :biennial-entry-id="biennialEntryId"
        :data--blockfirst="index === 0 ? '' : null"
        :data-last-block="index === (blocks?.length - 1) ? '' : null"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  blocks: {
    type: Array
  },
  defaultFontSize: {
    type: Boolean,
    default: true
  },
  biennialEntryId: {
    type: Number,
    required: false
  }
})

const linksFiltered = (links: any) => {
  return links.filter((link: any) => {
    return getRoutePresence(getNavigationItem(link)?.link?.routeObject?.name)
  })
}
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  gap: calc(var(--font-default--line-height) * 1.5);
}
</style>
