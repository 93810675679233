<template>
  <div :class="$style.root">
    <div
      v-if="label"
      :class="$style.label"
    >
      {{label}}
    </div>

    <ul
      :class="$style.items"
    >
      <li
        v-for="(item, index) in items"
        :key="item?.id || index"
      >
        <component
          :is="item?.url ? 'a' : 'div'"
          :href="item?.url ? item?.url : null"
          :target="item?.url ? '_blank' : null"
          :class="$style.item"
        >
          <core-image-component
            :image="item?.logo"
            :class="$style.image"
            :with-figure-tag="false"
            v-if="item?.logo"
          />
          <div
            v-else
            :class="$style.name"
          >
            {{ truncateName(item?.name) }}
          </div>
        </component>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  label: {
    type: String,
    required: false
  },
  items: {
    type: Array,
    required: true
  }
})

const truncateName = (name: string) => {
  return baseTruncateString(name, 4)
}
</script>

<style module lang="scss">
.root {

}

.label {
  composes: font-size-small from global;
  color: var(--color--secondary);
  margin-bottom: var(--unit--vertical);
}

.items {
  composes: reset-list from global;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: var(--unit--horizontal);

  @media (max-width: $breakpoint-s) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: $breakpoint-xs) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.item {
  aspect-ratio: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image {
  height: 100%;
  width: 100%;
  align-items: center;
  object-fit: contain;
}

.name {
  composes: font-size-small from global;
  text-align: center;
}
</style>
